import * as types from '../types';

const initialState = {
  me: null,
  company: null,
  operations: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_ME:
      return {
        ...state,
        status: types.COMPANY_ME,
        me: action.payload,
      }
    case types.COMPANY_INFO:
      return {
        ...state,
        status: types.COMPANY_INFO,
        company: action.payload,
      }
    case types.COMPANY_OPERATIONS:
      return {
        ...state,
        status: types.COMPANY_OPERATIONS,
        operations: action.payload,
      }
    default:
      return state
     }
};
