import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Footer } from '../../components';
import { Input, InputSelect } from '../../../../components';
import { setCurrencies } from '../../../../redux/actions/CurrenciesActions';
import { isValidElecCost, isValidElecPower } from '../../../../utils/validation';
import { getSelectedCurrencies, setSelectedPowers, getSelectedFunctionalCurrency } from '../../../../redux/actions/OperationSetupActions';

const formatPowers = () => {
  const selected = getSelectedCurrencies();
  var powers = {};
  Object.keys(selected).forEach(i => {
    const { value } = selected[i];
    powers[`${value}`] = {
      elecCost: '',
      fiat: 'CAD',
      powerDraw: '',
    };
  })
  return powers;
}

const formatErrors = () => {
  const selected = getSelectedCurrencies();
  var errors = {};
  Object.keys(selected).forEach(i => {
    const { value } = selected[i];
    errors[`${value}`] = {
      elecCost: '',
      powerDraw: '',
    };
  })
  return errors
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
}


class Power extends Component {
  state = {
    powers: formatPowers(),
    errors: formatErrors(),
  }

  componentDidMount() {
    const { options } = this.props;
    if (!options || options.length === 0) setCurrencies();
  }

  validate = () => {
    const { powers } = this.state;

    let isError = false;
    let errors = formatErrors();
    
    Object.keys(powers).forEach(cripto => {
      const { elecCost, powerDraw } = powers[cripto];
      if (!isValidElecCost(elecCost)) {
        isError = true;
        errors[`${cripto}`]['elecCost'] = `Invalid ${cripto} electricity cost`;
      }
      if (!isValidElecPower(powerDraw)) {
        isError = true;
        errors[`${cripto}`]['powerDraw'] = `Invalid ${cripto} power draw`;
      }
    })

    this.setState({ errors });
    if (!isError) setSelectedPowers(powers);

    return isError
  }

  renderInputsGroup = () => {
    var { powers, errors } = this.state;
    var inputsGroup = [];
    const selectedFC = getSelectedFunctionalCurrency()
    Object.keys(powers).forEach(cripto => {
      inputsGroup.push(
        <div key={cripto} style={{ margin: '0 0 32px 0' }}>
          <InputSelect
            title={`${cripto} Electricity Cost per kWh`}
            valueInput={powers[`${cripto}`]['powerDraw']}
            valueSelect={powers[`${cripto}`]['fiat']}
            type="text"
            nameInput="powerDraw"
            nameSelect="fiat"
            options={[ { value: selectedFC, title: selectedFC } ]}
            onChange={(e) => {
              const { name, value } = e.target;
              powers[`${cripto}`][`${name}`] = value;
              this.setState({ powers });
            }}
            error={errors[`${cripto}`]['powerDraw']}
            placeholder="Input electricity cost"
          />
          <Input
            title={`${cripto} Power Draw (kW)`}
            value={powers[`${cripto}`]['elecCost']}
            type="text"
            name="elecCost"
            placeholder="Input power draw"
            onChange={(e) => {
              const { name, value } = e.target;
              powers[`${cripto}`][`${name}`] = value;
              this.setState({ powers });
            }}
            error={errors[`${cripto}`]['elecCost']}
          />
        </div>
      )
    })
    return inputsGroup;
  }

  render() {
    const { nextOnClick } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Power'}
            currentStep={5}
            totalStep={5}
          />
          <form
            className="needs-validation"
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            { this.renderInputsGroup() }
          </form>
        </div>
        <div style={styles.footerContainer} >
          <Footer
            onClickPrev={() => nextOnClick("Wallet")}
            onClickNext={() => {
              const error = this.validate() // valification
              if (!error) nextOnClick("Complete")
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    fiat: state.currencies.fiatCurrencies,
  }
}

export default connect(mapStateToProps)(Power);
