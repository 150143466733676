import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Footer } from '../../components';
import { Input } from '../../../../components';
import { isValidAddress, isValidPoolApiKey } from '../../../../utils/validation';
import { setSelectedWalletAddresses, getSelectedPools } from '../../../../redux/actions/OperationSetupActions';

const formatWallets = () => {
  const selected = getSelectedPools();
  var wallets = {}
  Object.keys(selected).forEach((code) => {
    wallets[`${code}`] = {};
    const pools = selected[`${code}`];
    pools.forEach((pool) => {
      const { name } = pool;
      if (name) wallets[`${code}`][`${name}`] = [{ address: '', key: '',  walletName: '' }];
    })
  })
  return wallets;
}

const formatErrors = () => {
  const selected = getSelectedPools();
  var errors = {};
  Object.keys(selected).forEach((code) => {
    errors[`${code}`] = {};
    const pools = selected[`${code}`];
    pools.forEach((pool) => {
      const { name } = pool;
      if (name) errors[`${code}`][`${name}`] = [];
    })
  })
  return errors
}

const isUnique = (addresses, address) => {
  var numberOfSameAddress = 0;
  addresses.forEach((val) => {
    if (val.address === address) numberOfSameAddress += 1;
  })
  return numberOfSameAddress === 1;
}

class Wallet extends Component {
  state = {
    wallets: formatWallets(),
    errors: formatErrors(),
  }

  validate = () => {
    const { wallets } = this.state;

    let isError = false;
    let errors = formatErrors()

    Object.keys(wallets).forEach((code) => {
      const pools = wallets[`${code}`];
      Object.keys(pools).forEach((name) => {
        const addresses = pools[`${name}`];
        addresses.forEach((val) => {
          if (!isValidAddress(code, val.address)) {
            isError = true;
            errors[code][name].push(`Invalid ${code} address`);
          } else {
            if (isUnique(addresses, val.address)) {
              // check pool api key
              const poolApiKey = val.key
              if (code === 'DASH' && name === 'Coin Mine') {
                if (isValidPoolApiKey(poolApiKey) || poolApiKey === '') {
                  errors[code][name].push('');
                } else {
                  isError = true;
                  errors[code][name].push('The Api Key you have entered is not a valid format, please verify and try again. Alternatively, you may leave this box blank if you do not have an Api Key.');
                }
              } else {
                errors[code][name].push(``);
              }
            } else {
              isError = true;
              errors[code][name].push(`Duplicate ${code} address`);
            }
          }
        })
      });
    })

    this.setState({ errors });
    if (!isError) setSelectedWalletAddresses(wallets);

    return isError
  }

  renderInputs = () => {
    var { wallets, errors } = this.state;
    var inputs = [];
    Object.keys(wallets).forEach((code) => {
      const pools = wallets[`${code}`];
      Object.keys(pools).forEach((name) => {
        const addresses = pools[`${name}`];
        addresses.forEach((val, idx) => {
          inputs.push(
            <Input
              title={idx === 0 && `${name} (${code})`}
              type="text"
              id={code} // BTC, DASH ...
              name={name} // SlashPool, Coin Mine ...
              value={val.address}
              placeholder="Wallet address"
              onChange={(e) => {
                const { name, value } = e.target;
                if (wallets[`${code}`][`${name}`]) {
                  wallets[`${code}`][`${name}`][idx]['address'] = value;
                  this.setState({ wallets })
                }
              }}
              showDelete={addresses.length > 1}
              onClickDelete={() => {
                if (wallets[`${code}`][`${name}`]) {
                  wallets[`${code}`][`${name}`].splice(idx, 1);
                  this.setState({ wallets })
                }
              }}
              key={`${name}-${idx}`}
              error={errors[`${code}`][`${name}`][idx]}
              // below props are for the poolApiKey
              showSecondInput={code === 'DASH' && name === 'Coin Mine'}
              value2={val.key}
              placeholder2="Api key"
              onChange2={(e) => {
                const { value } = e.target;
                if (wallets[`${code}`][`${name}`]) {
                  console.log(wallets[`${code}`][`${name}`])
                  wallets[`${code}`][`${name}`][idx].key = value === '*****' ? '' : value;
                  this.setState({ wallets });
                }
              }}

              // name input
              showNickNameInput
              nameValue={val.walletName}
              nameOnChange={(e) => {
                const { value } = e.target;
                if (wallets[`${code}`][`${name}`]) {
                  wallets[`${code}`][`${name}`][idx]['walletName'] = value;
                  this.setState({ wallets })
                }
              }}
              namePlaceHolder="Wallet name"
            />
          )
        })
        inputs.push(this.renderAddButton(code, name))
      })
    })

    return inputs;
  }

  renderDashApiKeyMsg = () => {
    if (this.state.wallets.DASH) {
      return <div style={{ fontSize: 10 }}> *You must enter atleast 1 Coin Mine Api Key </div>
    }
  }

  renderAddButton = (code, name) => {
    return (
      <button
        key={`${code}-${name}`}
        style={styles.button}
        onClick={() => {
          var { wallets } = this.state;
          if (wallets[`${code}`][`${name}`]) {
            wallets[`${code}`][`${name}`].push({ address: '', key: '' })
            this.setState({ wallets });
          }
        }}>
        {`Add Another ${name} (${code}) Wallet Address`}
      </button>
    )
  }

  render() {
    const { nextOnClick } = this.props;
    console.log('the state is ', this.state)
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Wallet Addresses'}
            currentStep={4}
            totalStep={5}
          />
          <form
            className="needs-validation"
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            { this.renderInputs() }
            { this.renderDashApiKeyMsg() }
          </form>
        </div>
        <div style={styles.footerContainer} >
          <Footer
            onClickPrev={() => nextOnClick("Pools")}
            onClickNext={() => {
              const error = this.validate();  // valification
              if (!error) nextOnClick('Power');
            }}
          />
        </div>
      </div>
    )
  }
}


const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
  button: {
    border: 'none',
    outline: 0,
    color: '#63c2de',
    backgroundColor: 'white',
    fontSize: 12,
    margin: '0 0 16px 0',
    padding: 0,
  },
}

const mapStateToProps = state => {
  return {
    options: state.currencies.pools,
    selected: state.setup.selectedCryptocurrencies,
  }
}

export default connect(mapStateToProps)(Wallet);
