

import * as types from '../types';

const initialState = {
  wallets: [],
  balance: {},
  verifiDetails: {},
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.WALLETS:
      return {
        ...state,
        status: types.WALLETS,
        wallets: action.payload,
      };

    case types.FETCH_WALLET_BALANCE:
      return {
        ...state,
        status: types.FETCH_WALLET_BALANCE,
        balance: action.payload,
      };

    case types.FETCH_WALLET_VERIFICATION:
      return {
        ...state,
        loading: true,
        status: types.FETCH_WALLET_VERIFICATION,
        verifiDetails: {},
        error: null,
      };
    case types.FETCH_WALLET_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: types.FETCH_WALLET_VERIFICATION_SUCCESS,
        error: null,
        verifiDetails: action.payload,
      };

    case types.FETCH_WALLET_VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        status: types.FETCH_WALLET_VERIFICATION_FAILED,
        error: action.payload,
        verifiDetails: {},
      };
    default:
      return state;
  }
};
