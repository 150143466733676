import React, { Component } from 'react';
import Radium from 'radium';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import DatePicker from '../DatePicker';
import _ from 'lodash';
import moment from 'moment';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class DataTable extends Component {
  state = {
    data: this.props.data,
    unfilteredData: this.props.data,
  }

  filterDataByDate = (startDate, endDate) => {
    const filteredData = _.map(this.props.data, (val) => {
      if (moment(val.date).isBetween(startDate, endDate)) {
        return val;
      }
    });
    const data = _.without(filteredData, undefined)
    this.setState({ data: data })
  }

  renderCSVButton = (props) => {
    return (
      <div style={styles.buttonContainer}>
        <span />
        <ExportCSVButton { ...props } className="btn btn-success">Download CSV</ExportCSVButton>
      </div>
    );
  }

  renderSearchBar = (title, props) => {
    return (
      <div style={styles.searchContainer}>
        <h5 style={{...styles.searchBarConainer, margin: '0 0 16px 0'}}>{title}</h5>
        <div className="input-group mb-2" style={styles.searchBarConainer}>
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="icon-calendar" />
            </div>
          </div>
          <DatePicker
            onChange={(startDate, endDate) => {
              this.filterDataByDate(startDate, endDate)
            }}
            onReset={() => {
              const { unfilteredData } = this.state;
              this.setState({ data: unfilteredData });
            }}
            unfilteredData={this.state.unfilteredData}
          />
        </div>
        <div style={styles.searchBarConainer}>
          <SearchBar { ...props } placeholder="Filter Keywords"/>
        </div>
      </div>
    );
  }

  render() {
    const {
      title = '',
      keyField,
      columns,
      pagination = false,
      exportCSV = false,
      search = false,
    } = this.props;
    return (
      <ToolkitProvider
        keyField={keyField}
        data={this.state.data}
        columns={columns}
        exportCSV={exportCSV}
        search={search}
      >
        { props => (
          <div>
            { search && this.renderSearchBar(title, props.searchProps)}
            <BootstrapTable
              { ...props.baseProps }
              bootstrap4
              striped
              hover
              condensed
              pagination={ pagination && paginationFactory({
                hideSizePerPage: true,
              })}
            />
            { exportCSV && this.renderCSVButton(props.csvProps) }
          </div>
          )
        }
      </ToolkitProvider>
    )
  }
}

const styles = {
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 8px 0',
    '@media (max-width: 800px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
  },
  searchBarConainer: {
    width: '30%',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '16px 0 0 0'
  },
  button: {
    border: 'none',
    outline: 'none',
  }
}

export default Radium(DataTable)
