import axios from 'axios';
import * as types from '../types';
import store from '../store';
import { getConfig } from './AuthActions';
import { urls } from '../../utils/urls';
import { setCompanyDetails, setSelectedCurrencies/*, getSelectedPools*/, setSelectedWalletAddresses, setSelectedPowers } from './OperationSetupActions';

// MARK - Getter

export const getMe = () => {
  const state = store.getState();
  return state.operation.me;
};

export const getCompanyInfo = () => {
  const state = store.getState();
  return state.operation.company;
};

export const getCompanyOperations = async () => {
  const state = store.getState();
  return state.operation.operation;
};

// MARK - Setter

export const setCompanyOperations = async () => {
  try {
    const config = await getConfig();
    const res = await axios.get(urls.companyOperations, config);
    const { success, status = 0, data } = res.data;
    if (success && data) {
      console.log('[success] get company-operations', data);
      storeCompanyOperations(data);
    }
    return status;
  } catch (e) {
    return e;
  }
};

export const setMe = (me) => {
  store.dispatch({
    type: types.COMPANY_ME,
    payload: me,
  });
};


export const storeCompanyInfo = (info) => {
  store.dispatch({
    type: types.COMPANY_INFO,
    payload: info,
  });
};

// MARK - Setter

export const storeCompanyOperations = (data) => {
  const {
    reportingFiatCurrency,
    functionalFiatCurrency,
    yearEndMonth,
    yearEndDay,
    currencies,
    pools,
  } = data;

  // store compay info
  if (reportingFiatCurrency && functionalFiatCurrency && yearEndMonth && yearEndDay) {
    setCompanyDetails(`${yearEndMonth}/${yearEndDay}`, reportingFiatCurrency, functionalFiatCurrency);
  }

  // store criptocurrency & powers
  if (currencies) {
    var cripto = [];
    currencies.forEach((currency) => {
      const { cryptoCurrencyCode, } = currency;
      if (cryptoCurrencyCode) {
        cripto.push({ value: cryptoCurrencyCode, label: cryptoCurrencyCode });
      }
    });
    setSelectedCurrencies(cripto);
  }

  // store wallet address
  if (pools) {
    var walletAddresses = {}

    // format dict
    pools.forEach((pool) => {
      const { code, name } = pool;
      if (code && name) {
        walletAddresses[`${code}`] = {};
        walletAddresses[`${code}`][`${name}`] = [];
      }
    });

    // set data
    pools.forEach((pool) => {
      const { code, name, address, apiKeyEnd, walletName } = pool;
      if (code && name && address && walletName) {
        if (apiKeyEnd) {
          walletAddresses[`${code}`][`${name}`].push({ address, key: `*****${apiKeyEnd}`, walletName });
        } else {
          walletAddresses[`${code}`][`${name}`].push({ address, key: '', walletName });
        }
      }
    });
    setSelectedWalletAddresses(walletAddresses);
  }

  // store powers
  if (currencies) {
    var powers = {};

    // format dict
    currencies.forEach((currency) => {
      const { cryptoCurrencyCode } = currency;
      if (cryptoCurrencyCode) {
        powers[`${cryptoCurrencyCode}`] = { powerDraw: '', elecCost: '' };
      }
    });

    // set data
    currencies.forEach((currency) => {
      const { cryptoCurrencyCode, powerDrawWatts, electricityCostPerKwh } = currency;
      if (cryptoCurrencyCode && powerDrawWatts && electricityCostPerKwh) {
        powers[`${cryptoCurrencyCode}`]['powerDraw'] = powerDrawWatts;
        powers[`${cryptoCurrencyCode}`]['elecCost'] = electricityCostPerKwh;
        powers[`${cryptoCurrencyCode}`]['fiat'] = functionalFiatCurrency;
      }
    });

    setSelectedPowers(powers);
  }

  // store entire company operations
  store.dispatch({
    type: types.COMPANY_OPERATIONS,
    payload: data,
  });
};
