import * as types from '../types';

const initialState = {
  loggedIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGGEDIN:
      return {
        ...state,
        status: types.USER_LOGGEDIN,
        loggedIn: action.payload,
      }
    default:
      return state
  }
};
