
const host = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return 'https://staging.api.blockvantage.io/v1';
    case 'development':
      return 'http://localhost:8080/v1';
      // return 'https://staging.api.blockvantage.io/v1';
    case 'production':
      return 'https://api.blockvantage.io/v1';
    default:
      return 'https://staging.api.blockvantage.io/v1';
  }
};


export const urls = {
  login: `${host()}/login`,
  refreshToken: `${host()}/refresh`,
  companyInfo: `${host()}/company-info`,
  companyOperations: `${host()}/company-operations`,
  companyExchanges: `${host()}/company-exchanges`,
  allCurrencies: `${host()}/all-currencies`,
  pools: `${host()}/pools`,
  poolTransactions: `${host()}/pool-transactions`,
  wallets: `${host()}/wallets`,
  walletBalance: `${host()}/wallet-balance`,
  walletVerification: `${host()}/verification-wallets`,
  transaction: `${host()}/wallet-transactions`,
  exchanges: `${host()}/exchanges`,
  exchangeTransactions: `${host()}/exchanges-transactions`,
  summary: `${host()}/summary`,
  report: `${host()}/report`,
  companySummary: `${host()}/company-summary`,
  linkToBTC: 'http://btc.blockvantage.io/insight/tx',
  linkToDASH: 'http://dash.blockvantage.io/insight/tx',
};

export const getLinkToBlockexplorer = (code, hash) => {
  if (!code || !hash) return null;
  switch (code) {
    case 'BTC':
      return `${urls.linkToBTC}/${hash}`;
    case 'DASH':
      return `${urls.linkToDASH}/${hash}`;
    default:
      return null;
  }
};
