import React, { Component } from 'react';
import { AppHeader, AppNavbarBrand } from '@coreui/react';

import logo from '../../../../assets/img/brand/logo.svg';
import sygnet from '../../../../assets/img/brand/sygnet.svg';

class Header extends Component {
  render() {
    return (
      <AppHeader fixed>
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'BlockVantage Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'BlockVantage Logo' }}
        />
      </AppHeader>
    );
  }
}

export default Header;
