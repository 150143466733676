import axios from 'axios';
import decode from 'jwt-decode';
import * as types from '../types';
import * as keys from '../keys';
import store from '../store';
import { urls } from '../../utils/urls';
import { setCurrencies, setPools } from './CurrenciesActions';
import { setCompanyOperations } from './OperationActions';

const tokenState = {
  valid: 'Valid',
  expired: 'Expired',
  notExist: 'NotExist',
};

// MARK - APIs

export const attemptLogin = async (email, password) => {
  const details = { email, password };

  const data = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');
  try {
    const res = await axios.post(urls.login, data);
    const token = res.data.access_token;
    if (token) setToken(token);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

const populateData = async (history) => {
  try {
    await setCurrencies(); // set fiat & criptocurrency
    await setPools();  // set pools
    const status = await setCompanyOperations();
    return status === 0
      ? history.push('/onboarding')
      : login();
  } catch(e) {
    return Promise.reject(e);
  }
}

export const checkLoginState = async (history) => {

  // get token status
  const status = getTokenStatus();

  try {
    // check login state
    if (status === tokenState.expired) {
      await refreshToken()  // refresh token
      await populateData(history) // popilate data to redux store
      return
    } else {
      return status === tokenState.valid
        ? await populateData(history)
        : logout();
    }
  } catch(e) {
    return Promise.reject(e);
  }
}

// MARK - Setter

const setToken = (token) => {
  localStorage.setItem(keys.ACCESS_TOKEN_KEY, token);
};

const clearToken = () => {
  localStorage.removeItem(keys.ACCESS_TOKEN_KEY);
};

export const login = () => {
  store.dispatch({
    type: types.USER_LOGGEDIN,
    payload: true,
  });
};

export const logout = () => {
  clearToken();
  store.dispatch({
    type: types.USER_LOGGEDIN,
    payload: false,
  });
};

// MARK - Getter

export const getToken = () => {
  const token = localStorage.getItem(keys.ACCESS_TOKEN_KEY);
  return token;
};

const getTokenStatus = () => {
  const { valid, expired, notExist } = tokenState;

  const token = getToken();
  if (!token) return notExist; // token not exist in the storage

  const tokenDecoded = decode(token);
  if (!tokenDecoded.exp) {
    clearToken();
    return tokenState.notExist; // token is not in the right format
  }

  // get expiration date
  const expirationDate = new Date(0);
  expirationDate.setUTCSeconds(tokenDecoded.exp);
  return expirationDate < new Date() ? expired : valid;
}

const refreshToken = async () => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get(urls.refreshToken, config);
    const { success, data } = res.data;
    if (success && data && data.access_token) {
      const newToken = data.access_token;
      return newToken;
    }
    console.log('[warning] token refresh failed', res);
    return Promise.reject(res);
  } catch (e) {
    console.log('[warning] token refresh failed', e);
    return Promise.reject(e);
  }
};

export const getConfig = async (params) => {
  const { expired } = tokenState;
  const status = getTokenStatus();
  try {
    if (status === expired) await refreshToken();
    return {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      params,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPDFConfig = async (params) => {
  const { expired } = tokenState;
  const status = getTokenStatus();
  try {
    if (status === expired) await refreshToken();
    return {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Accept': 'application/pdf',
      },
      responseType: 'arraybuffer',
      params,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};
