import axios from 'axios';
import * as types from '../types';
import store from '../store';
import { getConfig } from './AuthActions';
import { urls } from '../../utils/urls';
import { storeCompanyInfo ,storeCompanyOperations } from './OperationActions';

// MARK - Company Operations

export const postCompanyOperations = async () => {
  const cryptos = getSelectedCurrencies();
  const pools = getSelectedPools();
  const wallets = getSelectedWalletAddressed();
  const powers = getSelectedPowers();

  // console.log('[cryptos]', cryptos);
  // console.log('[pools]', pools);
  // console.log('[wallets]', wallets);
  // console.log('[powers]', powers);

  const formatAddresses = (addresses) => {
    var arr = [];
    addresses.forEach((val) => {
      const addressObj = {}
      addressObj.address = val.address;
      addressObj.name = val.walletName ? val.walletName : 'unnamed';
      if (val.address) arr.push(addressObj);
    });
    return arr;
  };

  const formatApiKeys = (addresses) => {
    var arr = [];
    addresses.forEach((val) => {
      const { address, key } = val;
      if (address && key && key.length > 0) arr.push({ [address]: key });
    });
    return arr;
  };

  const getPools = (code) => {
    var selectedPools = [];
    const wallet = wallets[code];
    Object.keys(wallet).forEach((key) => {
      const walletAddresses = formatAddresses(wallet[key]);
      pools[code].forEach((pool) => {
        const { name, id } = pool;
        if (name && id && key === name) {
          if (code === 'DASH' && name === 'Coin Mine') {
            const poolApiKeys = formatApiKeys(wallet[key]);
            selectedPools.push(
              {
                poolId: id,
                walletAddresses,
                poolApiKeys,
              },
            );
          } else {
            selectedPools.push(
              {
                poolId: id,
                walletAddresses,
              },
            );
          }
        }
      });
    });
    return selectedPools;
  };

  let currencies = [];
  Object.keys(cryptos).forEach((key) => {
    const code = cryptos[key].value; // BTC, DASH etc...
    const pools = getPools(code);
    currencies.push(
      {
        code,
        powerDrawWatts: powers[code].powerDraw,
        electricityCostPerKwh: powers[code].elecCost,
        pools,
      }
    );
  });

  // console.log('[info] param: currencies', currencies);
  try {
    const config = await getConfig();
    const res = await axios.post(urls.companyOperations, { currencies }, config);
    if (res) {
      // console.log('[success] post company operations', res);
      storeCompanyOperations(res);  // set to redux store
      return res;
    }
    return Promise.reject('Failed to update companry operations');
  } catch (e) {
    return Promise.reject(e);
  }
};

// MARK - Company Details

export const setCompanyDetails = (date, repoCurrency, funcCurrency) => {
  setSelectedYearEndDate(date);
  setSelectedReportingCurrency(repoCurrency);
  setSelectedFunctionalCurrency(funcCurrency);
}

export const postCompnayDetails = async () => {

  const yearEndDate = getSelectedYearEndDate();
  const reportingFiatCurrency = getSelectedReportingCurrency();
  const functionalFiatCurrency = getSelectedFunctionalCurrency();

  const arrDate = yearEndDate.split('/');
  if (arrDate.length !== 2) return;

  const params = {
    reportingFiatCurrency,
    functionalFiatCurrency,
    yearEndMonth: Number(arrDate[0]),
    yearEndDay: Number(arrDate[1]),
  };

  try {
    const config = await getConfig();
    const res = await axios.post(urls.companyInfo, params, config);
    // console.log('[success] post company details', res);
    storeCompanyInfo(res); // store to redux
    return res;
  } catch (e) {
    return e;
  }
};

export const getSelectedYearEndDate = () => {
  const state = store.getState();
  return state.setup.selectedYearEndDate;
};

const setSelectedYearEndDate = (date) => {
  store.dispatch({
    type: types.SELECTED_COMPANY_YEAR_END_DATE,
    payload: date,
  });
};

export const getSelectedReportingCurrency = () => {
  const state = store.getState();
  return state.setup.selectedReportingCurrency;
};

const setSelectedReportingCurrency = (currency) => {
  store.dispatch({
    type: types.SELECTED_REPORTING_CURRENCY,
    payload: currency,
  });
};

export const getSelectedFunctionalCurrency = () => {
  const state = store.getState();
  return state.setup.selectedFunctionalCurrency;
};

const setSelectedFunctionalCurrency = (currency) => {
  store.dispatch({
    type: types.SELECTED_FUNCTIONAL_CURRENCY,
    payload: currency,
  });
};

// MARK - Currencies

export const setSelectedCurrencies = (currencies) => {
  store.dispatch({
    type: types.SELECTED_CRIPT_CURRENCIES,
    payload: currencies,
  });
};

export const getSelectedCurrencies = () => {
  const state = store.getState();
  return state.setup.selectedCryptocurrencies;
};

export const didSelectCurrency = (currency) => {
  const currencies = getSelectedCurrencies();
  if (currencies && currencies.length > 0) {
    currencies.forEach((c) => {
      if (currency === c) return true;
    });
  }
  return false;
};

// MARK - Pools

export const getSelectedPools = () => {
  const state = store.getState();
  return state.setup.selectedPools;
};

export const setSelectedPools = (pools) => {
  store.dispatch({
    type: types.SELECTED_POOLS,
    payload: pools,
  });
};

// MARK - Wallet Addresses

export const getSelectedWalletAddressed = () => {
  const state = store.getState();
  return state.setup.selectedWalletAddresses;
};

export const setSelectedWalletAddresses = (wallets) => {
  store.dispatch({
    type: types.SELECTED_WALLET_ADDRESSES,
    payload: wallets,
  });
};

// MARK - Power

export const getSelectedPowers = () => {
  const state = store.getState();
  return state.setup.selectedPowers;
};

export const setSelectedPowers = (powers) => {
  store.dispatch({
    type: types.SELECTED_POWERS,
    payload: powers,
  });
};
