import React from 'react';

export default ({
  onClickPrev = null,
  onClickNext = null,
  prevTitle = "Prev",
  nextTitle = "Next",
}) => (
  <div style={styles.container}>
    { onClickPrev
      ? <button
          className="btn btn-secondary"
          style={styles.button}
          onClick={onClickPrev}>{prevTitle}</button>
      : <span />
    }
    { onClickNext
      ? <button
          className="btn btn-primary"
          style={styles.button}
          onClick={onClickNext}>{nextTitle}</button>
      : <span />
    }
  </div>
);

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: 80,
  },
};
