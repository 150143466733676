import React, { Component } from 'react';
import { ProgressBar, Footer } from '../../components';
import { postCompnayDetails, postCompanyOperations } from '../../../../redux/actions/OperationSetupActions';
import { login } from '../../../../redux/actions/AuthActions';

export default class Complete extends Component {
  state = {
    setupSuccess: false,
  }

  componentWillMount() {
    this.setupOperation();
  }

  setupOperation = async () => {
    try {
      await postCompnayDetails();
      await postCompanyOperations();
      console.log('[info] success setup operations');
      this.setState({ setupSuccess: true })
    } catch (e) {
      this.setState({ setupSuccess: false })
      alert(e);
    }
  }

  render() {
    const { nextOnClick } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Complete'}
            currentStep={6}
            totalStep={5}
          />
          <p>
            All done!
            <br/>
            <br/>
            If you want to make any changes to these settings in the future, please go to <b>Operations</b>.
            <br/>
            <br/>
            <b>Exchanges</b> allows you to integrate with cryptocurrency exchanges.
            <br/>
            <br/>
            <b>Verification</b> allows you to confirm wallets ownership.
            <br/>
            <br/>
            Lastly, <b>Export</b> allows you to export data in CSV format.
          </p>
        </div>
        <div style={styles.footerContainer} >
          <Footer
            nextTitle="Finish"
            onClickPrev={() => {
              this.state.setupSuccess
                ? nextOnClick("Power")
                : alert('Error: Failed setup company operations')
            }}
            onClickNext={() => login()}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
}
