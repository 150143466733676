import * as types from '../types';

const initialState = {
  summary: null,
  reportBlob: null,
  loadingReport: null,
  reportError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EXPORT_SUMMARY:
      return {
        ...state,
        status: types.EXPORT_SUMMARY,
        summary: action.payload,
      };
    case types.REPORT_FETCH:
      return {
        ...state,
        loadingReport: true,
        reportError: null,
      };
    case types.REPORT_FETCH_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        reportError: null,
      };

    case types.REPORT_FETCH_FAILED:
      return {
        ...state,
        status: types.REPORT_FETCH_FAILED,
        loadingReport: false,
        reportError: 'fetching report failed',
      };

    default:
      return state;
  }
};
