import React from 'react';
import { Route } from 'react-router-dom';

export default ({ title, to, className, style, onClick }) => (
  <Route
    render={({ history }) => (
      <button
        className={className}
        style={{ ...style, ...styles.button }}
        onClick={() => {
          if (onClick) onClick();
          history.push(to)
        }}
      >{title}</button>
    )}
  />
);

const styles = {
  button: {
    border: 'none',
    outline: 0,
  },
};
