import React, { Component } from 'react';
import { AppHeader } from '@coreui/react';
import Radium from 'radium';
import Header from './Header';
import Form from './Form';

class Login extends Component {
  render() {
    return (
      <div className="app" style={styles.container}>
        <AppHeader fixed>
          <Header height={headerHeight}/>
        </AppHeader>
        <div style={styles.bodyContainer}>
          <div style={styles.formContainer}>
            <Form history={this.props.history}/>
          </div>
        </div>
      </div>
    )
  }
}

const headerHeight = 100;
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'colmin',
  },
  headerContainer: {
    width: '100%',
    height: headerHeight,
  },
  bodyContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    minWidth: 500,
    '@media (max-width: 500px)': {
      minWidth: 'auto',
      width: '100%',
      padding: 16,
    },
  },
};

export default Radium(Login);
