import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default ({
  title,
  body,
  submitButtonTitle,
  isOpen,
  toggleModal,
  handleSubmit,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} style={styles.header}>
        {/* <h5 style={styles.title}>{title}</h5> */}
        {title}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" style={styles.buttonSubmit} onClick={() => {
          toggleModal && toggleModal();
          handleSubmit && handleSubmit();
        } }>{submitButtonTitle ? submitButtonTitle : 'OK'}</button>
      </ModalFooter>
    </Modal>
  );
}

const styles = {
  header: {
    background: '#63c2de',
    width: '100%',
    height: '100%',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  buttonSubmit: {
    padding: '4px 24px',
  },
};
