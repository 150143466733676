import axios from 'axios';
import * as types from '../types';
import store from '../store';
import { getConfig } from './AuthActions';
import { urls } from '../../utils/urls';

// MARK - Getter

export const getCryptoCurrencies = () => {
  const state = store.getState();
  return state.currencies.cryptoCurrencies;
};

export const getFiatCurrencies = () => {
  const state = store.getState();
  return state.currencies.fiatCurrencies;
};

export const getPools = () => {
  const state = store.getState();
  return state.currencies.pools;
};

// MARK - Setter

export const setupAll = async () => {
  try {
    await setCurrencies();
    await setPools();
    return;
  } catch(e) {
    return e
  }
}

export const setCurrencies = async () => {
  try {
    const config = await getConfig();
    const res = await axios.get(urls.allCurrencies, config);
    const { success, data } = res.data;
    if (success && data) {
      console.log('[success] get all currencies', data);
      const { cryptoCurrencies, fiatCurrencies } = data;
      storeCryptoCurrency(cryptoCurrencies ? cryptoCurrencies : []);
      storeFiatCurrency(fiatCurrencies ? fiatCurrencies: []);
    }
    return res;
  } catch(e) {
    return e;
  }
};

export const setPools = async () => {
  try {
    const config = await getConfig();
    const res = await axios.get(urls.pools, config);
    const { success, data } = res.data;
    if (success && data) {
      const { pools } = data;
      storePool(pools ? pools : []);
    }
    return res;
  } catch (e) {
    return e;
  }
}

// MARK - Store

const storeCryptoCurrency = (currencies) => {
  let arr = [];
  for (var key in currencies) {
    if (currencies.hasOwnProperty(key)) {
      arr.push(currencies[key])
    }
  }
  store.dispatch({
    type: types.CRYPTO_CURRENCIES,
    payload: arr,
  });
};

const storeFiatCurrency = (currencies) => {
  let arr = [];
  for (var key in currencies) {
    if (currencies.hasOwnProperty(key)) {
      arr.push(currencies[key])
    }
  }
  store.dispatch({
    type: types.FIAT_CURRENCIES,
    payload: arr,
  });
};

const storePool = (pools) => {
  store.dispatch({
    type: types.POOLS,
    payload: pools,
  });
};
