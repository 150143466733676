// user
export const USER_LOGGEDIN = 'USER_LOGGEDIN';

// wallets
export const WALLETS = 'WALLETS';
export const FETCH_WALLET_BALANCE = 'FETCH_WALLET_BALANCE';
export const FETCH_WALLET_VERIFICATION = 'FETCH_WALLET_VERIFICATION';
export const FETCH_WALLET_VERIFICATION_SUCCESS = 'FETCH_WALLET_VERIFICATION_SUCCESS';
export const FETCH_WALLET_VERIFICATION_FAILED = 'FETCH_WALLET_VERIFICATION_FAILED';


// transactions
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILED = 'FETCH_TRANSACTIONS_FAILED';

// pool transactions
export const FETCH_POOL_TRANSACTIONS = 'FETCH_POOL_TRANSACTIONS';
export const FETCH_POOL_TRANSACTIONS_FAILED = 'FETCH_POOL_TRANSACTIONS_FAILED';
export const FETCH_POOL_TRANSACTIONS_SUCCESS = 'FETCH_POOL_TRANSACTIONS_SUCCESS';

// exchanges transactions
export const FETCH_EXCHANGES_TRANSACTIONS = 'FETCH_EXCHANGES_TRANSACTIONS';
export const FETCH_EXCHANGES_TRANSACTIONS_FAILED = 'FETCH_EXCHANGES_TRANSACTIONS_FAILED';
export const FETCH_EXCHANGES_TRANSACTIONS_SUCCESS = 'FETCH_EXCHANGES_TRANSACTIONS_SUCCESS';

// company
export const COMPANY_ME = 'COMPANY_ME';
export const COMPANY_INFO = 'COMPANY_INFO';
export const COMPANY_OPERATIONS = 'COMPANY_OPERATIONS';

// currencies & pools fetched from server
export const CRYPTO_CURRENCIES = 'CRYPTO_CURRENCIES';
export const FIAT_CURRENCIES = 'FIAT_CURRENCIES';
export const POOLS = 'POOLS';

// company operation
export const SELECTED_COMPANY_YEAR_END_DATE = 'SELECTED_COMPANY_YEAR_END_DATE';
export const SELECTED_REPORTING_CURRENCY = 'SELECTED_REPORTING_CURRENCY';
export const SELECTED_FUNCTIONAL_CURRENCY = 'SELECTED_FUNCTIONAL_CURRENCY';
export const SELECTED_CRIPT_CURRENCIES = 'SELECTED_CRIPT_CURRENCIES';
export const SELECTED_WALLET_ADDRESSES = 'SELECTED_WALLET_ADDRESSES';
export const SELECTED_POWERS = 'SELECTED_POWERS';
export const SELECTED_POOLS = 'SELECTED_POOLS';

// dashboard
export const DASHBOARD_TRANSACTION = 'DASHBOARD_TRANSACTION';
export const FETCH_COMPANY_SUMMARY = 'FETCH_COMPANY_SUMMARY';
export const FETCH_COMPANY_SUMMARY_SUCCESS = 'FETCH_COMPANY_SUMMARY_SUCCESS';
export const FETCH_COMPANY_SUMMARY_FAILED = 'FETCH_COMPANY_SUMMARY_FAILED';

// exchangs
export const EXCHANGES = 'EXCHANGES';
export const EXCHANGES_COMPANY = 'EXCHANGES_COMPANY';
export const EXCHANGES_SELECTED_ID = 'EXCHANGES_SELECTED_ID';

// export
export const EXPORT_SUMMARY = 'EXPORT_SUMMARY';
export const REPORT_FETCH = 'REPORT_FETCH';
export const REPORT_FETCH_SUCCESS = 'REPORT_FETCH_SUCCESS';
export const REPORT_FETCH_FAILED = 'REPORT_FETCH_FAILED';
