import React from 'react';
import Loadable from 'react-loadable';
import Home from './Home';

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import('./views/Transactions'),
  loading: Loading,
});

const Payouts = Loadable({
  loader: () => import('./views/Payouts'),
  loading: Loading,
});

const Exchanges = Loadable({
  loader: () => import('./views/Exchanges'),
  loading: Loading,
});

const Verification = Loadable({
  loader: () => import('./views/Verification'),
  loading: Loading,
})

const UpdateOperations = Loadable({
  loader: () => import('./views/UpdateOperations'),
  loading: Loading,
})

const Export = Loadable({
  loader: () => import('./views/Export'),
  loading: Loading,
})

// const Help = Loadable({
//   loader: () => import('./views/Help'),
//   loading: Loading,
// })

const routes = [
  { path: '/', name: 'Home', component: Home, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/transactions', name: 'Transactions', component: Transactions },
  { path: '/payouts', name: 'Payouts', component: Payouts },
  { path: '/exchanges', name: 'Exchanges', component: Exchanges},
  { path: '/verification', name: 'Verification', component: Verification},
  { path: '/updateOperations', name: 'Update Operations', component: UpdateOperations},
  { path: '/export', name: 'Export', component: Export},
  // { path: '/help', name: 'Help', component: Help},
];

export default routes;
