import * as types from '../types';

const initialState = {
  exchanges: null,
  companyExchanges: null,
  selectedExchangeId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EXCHANGES:
      return {
        ...state,
        status: types.EXCHANGES,
        exchanges: action.payload,
      }
    case types.EXCHANGES_COMPANY:
      return {
        ...state,
        status: types.EXCHANGES_COMPANY,
        companyExchanges: action.payload,
      }
    case types.EXCHANGES_SELECTED_ID:
      return {
        ...state,
        status: types.EXCHANGES_SELECTED_ID,
        selectedExchangeId: action.payload,
      }
    default:
      return state
     }
};
