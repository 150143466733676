import React, { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import Workbook, { Sheet, Column } from 'react-excel-workbook';
const { ExportCSVButton } = CSVExport;

export const DownloadCsvButtonYear = ({
  title,
  keyField,
  columns,
  data,
}) => {
  return (
    <ToolkitProvider
      keyField={keyField}
      data={data}
      columns={columns}
      exportCSV>
      {
        props => (
          <Fragment>
            <ExportCSVButton
              { ...props.csvProps }
              className="btn"
              style={styles.button}>{title}</ExportCSVButton>
            <div style={{ display: "none" }}>
              <BootstrapTable { ...props.baseProps } />
            </div>
          </Fragment>
        )
      }
    </ToolkitProvider>
  )
}

export const DonwloadCsvButtonSummary = ({
  title,
  data,
}) => {
  const formatData = (data) => {
    if (Object.keys(data).length > 0) {
      var dataFormatted = [];
      Object.keys(data).forEach((key) => {
        dataFormatted.push(data[key]);
      });
      return dataFormatted;
    }
    return data;
  };

  const convetItemToString = (data) => {
    if (Array.isArray(data)) {
      var dataFormatted = [];
      data.forEach((d) => {
        var item = {};
        Object.keys(d).forEach((key) => {
          item[key] = `${d[key]}`;
        });
        dataFormatted.push(item);
      });
      return dataFormatted;
    }
    return data;
  };

  const formatStatus = (row) => {
    switch (row.status) {
      case 0:
        return 'Uninitiated';
      case 1:
        return 'Pending';
      case 2:
        return 'Verified';
      default:
        return 'Uninitiated';
    }
  };

  const formatUsdValue = (row) => {
    const value = row.usdValue;
    const usdValue = Number(value).toFixed(2).toLocaleString();
    return `$ ${usdValue}`;
  };

  const formatUsdValueIn = (row) => {
    const value = row.valueInUsd;
    const usdValue = Number(value).toFixed(2).toLocaleString();
    return `$ ${usdValue}`;
  };

  const formatUsdValueOut = (row) => {
    const value = row.valueOutUsd;
    const usdValue = Number(value).toFixed(2).toLocaleString();
    return `$ ${usdValue}`;
  };

  const formatBalance = (row) => {
    const balance = Math.round(Number(row.balance));
    return `${balance}`;
  };

  const formatFiscalSum = (row) => {
    const fiscalSum = Math.round(Number(row.fiscalSum));
    return `${fiscalSum}`;
  };

  const formatFiscalUsdValue = (row) => {
    const value = row.fiscalSumValue;
    const usdValue = Number(value).toFixed(2).toLocaleString();
    return `$ ${usdValue}`;
  };

  const formatAverageValue = (row) => {
    const value = row.weightedAverageValue;
    const usdValue = Number(value).toFixed(2).toLocaleString();
    return `$ ${usdValue}`;
  };

  const formatCoinEarned = (row) => {
    const earned = Number(row.coinMined).toFixed(1);
    return `${earned}`;
  };

  const formatValueIn = (row) => {
    const valueFormatted = Math.round(Number(row.valueIn));
    return `${valueFormatted}`;
  };

  const formatValueOut = (row) => {
    const valueFormatted = Math.round(Number(row.valueOut));
    return `${valueFormatted}`;
  };

  const getMonth = (row) => {
    const index = Number(row.month) - 1;
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return month[index];
  };

  const formatTxnValue = (row) => {
    const { direction, txn_value_in, txn_value_out } = row;
    if (direction === '0') { return txn_value_in; }
    if (direction === '1') { return txn_value_out; }
  };

  const { coin, wallet, month, nonMinedTransactions, direction } = data;
  return (
    <Workbook
      filename="BlockVantage_Summary.xlsx"
      element={
        <button className="btn" style={styles.button}>
          {title}
        </button>
      }
    >
      <Sheet data={convetItemToString(formatData(coin))} name="Coin">
        <Column label="Coin" value="crypto_currency_code" />
        <Column label="Coin Balance" value={formatBalance} />
        <Column label="Usd Value (sum)" value={formatUsdValue} />
        <Column label="USD Rate" value="usdRate" />
        <Column label="CAD Rate" value="cadRate" />
        <Column label="Combined Fiscal Coin Value Sum" value={formatFiscalSum} />
        <Column label="Combined Fiscal Fiat Value Sum" value={formatFiscalUsdValue} />
        <Column label="Weighted Average Value" value={formatAverageValue} />
      </Sheet>

      <Sheet data={convetItemToString(wallet)} name="Wallet">
        <Column label="Coin" value="crypto_currency_code" />
        <Column label="Wallet" value="address" />
        <Column label="Coin Balance" value={formatBalance} />
        <Column label="Usd Value (sum)" value={formatUsdValue} />
        <Column label="Verification status" value={formatStatus} />

        <Column label="Fiscal Coin Value Sum" value={formatFiscalSum} />
        <Column label="Fiscal Fiat Value Sum" value={formatFiscalUsdValue} />
      </Sheet>
      <Sheet data={convetItemToString(formatData(month))} name="Month">
        <Column label="Year" value="year" />
        <Column label="Month" value={getMonth} />
        <Column label="Coin" value="coin" />
        <Column label="Coin earned" value={formatCoinEarned} />
        <Column label="Usd Value (sum)" value={formatUsdValue} />
      </Sheet>
      <Sheet data={convetItemToString(formatData(direction))} name="Direction">
        <Column label="Year" value="year" />
        <Column label="Coin" value="code" />
        <Column label="Address" value="address" />
        <Column label="Value In" value={formatValueIn} />
        <Column label="In Usd Value" value={formatUsdValueIn} />
        <Column label="Value out" value={formatValueOut} />
        <Column label="Out Usd Value" value={formatUsdValueOut} />
      </Sheet>
      <Sheet data={convetItemToString(nonMinedTransactions)} name="Non Mining Transactions">
        <Column label="Coin" value="crypto_currency_code" />
        <Column label="From Address" value="txn_from_address" />
        <Column label="To Address" value="txn_to_address" />
        <Column label="Transaction amount" value={formatTxnValue} />
        <Column label="Transaction usd value" value={formatUsdValue} />
        <Column label="Transaction hash" value="txn_hash" />
        <Column label="Timestamp" value="txn_timestamp" />

      </Sheet>
    </Workbook>
  );
};

const styles = {
  button: {
    color: 'white',
    background: '#4dbd74',
    margin: 0,
    padding: 0,
    border: 'none',
  },
}
