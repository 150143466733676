import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Footer } from '../../components';
import { Input, Select } from '../../../../components';
import { isValidMMDD } from '../../../../utils/validation';
import { setCompanyDetails } from '../../../../redux/actions/OperationSetupActions';
import { setCurrencies } from '../../../../redux/actions/CurrenciesActions';

class Company extends Component {
  state = {
    date: '',
    dateError: '',
    repoCurrency: 'CAD',
    repoCurrencyError: '',
    funcCurrency: 'CAD',
    funcCurrencyError: '',
    options: [
      { value: 'CAD', title: 'Canadian Dollar' },
    ]
  }

  componentDidMount() {
    const { options } = this.props;
    if (!options || options.length === 0) setCurrencies();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name] : value });
  }

  validate = () => {
    const { date, repoCurrency, funcCurrency } = this.state;

    let isError = false;
    let errors = {
      dateError: '',
      repoCurrencyError: '',
      funcCurrencyError: '',
    }
    
    if (!isValidMMDD(date)) {
      isError = true;
      errors.dateError = 'Invalid date format';
    }

    if (repoCurrency.length === 0) {
      isError = true;
      errors.repoCurrencyError = 'Please select currency';
    }

    if (funcCurrency.length === 0) {
      isError = true;
      errors.funcCurrencyError = 'Please select currency';
    }

    this.setState({
      ...this.state,
      ...errors
    });

    return isError
  }

  submit = () => {
    const { date, repoCurrency, funcCurrency } = this.state;
    setCompanyDetails(date, repoCurrency, funcCurrency);  // store to redux
    this.props.nextOnClick('Currencies');  // move to currency setup
  }

  formatOptions = (options) => {
    let opt = [];
    options.forEach( c => {
      const { code, name } = c;
      if (code && name) {
        opt.push({ value: code, title: name });
      }
    });
    return opt;
  }

  render() {
    const { dateError, repoCurrency, repoCurrencyError, funcCurrency, funcCurrencyError } = this.state;
    const { options } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Company Details'}
            currentStep={1}
            totalStep={5}
          />
          <form
            className="needs-validation"
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            <Input
              title="Year End Date"
              type="text"
              name="date"
              placeholder="MM/DD"
              onChange={this.onChange}
              error={dateError}
              maxLength={'5'}
            />
            <Select
              title="Reporting Currency"
              name="repoCurrency"
              value={repoCurrency}
              options={this.formatOptions(options)}
              onChange={this.onChange}
              error={repoCurrencyError}
            />
            <Select
              title="Functional Currency"
              name="funcCurrency"
              value={funcCurrency}
              options={this.formatOptions(options)}
              onChange={this.onChange}
              error={funcCurrencyError}
            />
          </form>
        </div>
        <div style={styles.footerContainer} >
          <Footer onClickNext={(e) => {
            const error = this.validate();  // validation            
            if (!error) this.submit(); // submit form
          }} />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
}

const mapStateToProps = state => {
  return {
    options: state.currencies.fiatCurrencies,
  }
}

export default connect(mapStateToProps)(Company);
