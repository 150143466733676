import React from 'react';
import v8n from 'v8n';

const Select = ({
  title,
  id,
  name,
  value,
  options,
  onChange,
  error = ''
 }) => {
  // check options
  if (!isValid(options)) return null;

  const className = error.length > 0
    ? 'form-control is-invalid'
    : 'form-control';
  return (
    <div className="form-group">
      {title && <label>{title}</label>}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={className}>
        {
          options.map((o, i) => (
            <option value={o.value} key={i}>{o.title}</option>
          ))
        }
      </select>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

const isValid = (options) => {
  if (!v8n().array().test(options) || options.length === 0) return false;
  options.forEach(o => {
    if (!o.hasOwnProperty('value') || !o.hasOwnProperty('title')) return false;
  });
  return true;
}

export default Select;
