import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import _ from 'lodash';

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      title: 'Select data range',
      ranges: getDateRanges(),
    }
  }

  onChangeDate = (date) => {
    const { startDate, endDate } = date.ranges;
    if (startDate && endDate) {
      const ranges = getDateRanges(moment(startDate), moment(endDate))
      const title = startDate === endDate
        ? `${moment(startDate).format('YYYY/MM/DD')}`
        : `${moment(startDate).format('YYYY/MM/DD')} ~ ${moment(endDate).format('YYYY/MM/DD')}`;
      this.setState({ ranges, title });
    }
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen })
  }

  onReset = () => {
    const { unfilteredData } = this.props;
    const startDate = _.last(unfilteredData).txn_date;
    const endDate = _.first(unfilteredData).txn_date;
    this.props.onReset();
    this.setState({
      isOpen: false,
      ranges: getDateRanges(moment(startDate), moment(endDate)),
      title: 'Select data range',
    })

  }

  onSubmit = () => {
    const { onChange } = this.props;
    const { startDate, endDate } = this.state.ranges[0];
    if (startDate && endDate && onChange) {
      onChange(startDate, endDate);
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { isOpen, ranges, title } = this.state;
    return (
      <React.Fragment>
        <button className="form-control text-left" onClick={this.toggle}>{title}</button>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <div style={styles.container}>
            <DateRange
              ranges={ranges}
              onChange={this.onChangeDate}
              showDateDisplay={false}
              rangeColors={['#20a8d8']}
            />
            <div style={styles.footer}>
              <button className="btn btn-secondary" onClick={this.onReset}>Reset</button>
              <button className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const getDateRanges = (start, end) => {
  return [{
    startDate: start ? start : moment(),
    endDate: end ? end: moment(),
    key: 'ranges',
  }]
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    width: 320,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '16px 0 24px 0'
  },
};
