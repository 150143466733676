import React, { Component } from 'react';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import logo from '../../../../assets/img/brand/logo.svg';
import sygnet from '../../../../assets/img/brand/sygnet.svg';

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'BlockVantage Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'BlockVantage Logo' }}
        />
      </React.Fragment>
    );
  }
}

export default Header;
