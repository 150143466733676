import * as types from '../types';

const initialState = {
  cryptoCurrencies: [],
  fiatCurrencies: [],
  pools: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CRYPTO_CURRENCIES:
      return {
        ...state,
        status: types.CRYPTO_CURRENCIES,
        cryptoCurrencies: action.payload,
      }
    case types.FIAT_CURRENCIES:
      return {
        ...state,
        status: types.FIAT_CURRENCIES,
        fiatCurrencies: action.payload,
      }
    case types.POOLS:
      return {
        ...state,
        status: types.POOLS,
        pools: action.payload,
      }
    default:
      return state
     }
};
