export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Transactions',
      url: '/transactions',
      icon: 'icon-arrow-up-circle',
    },
    {
      name: 'Payouts',
      url: '/payouts',
      icon: 'icon-wallet',
    },
    {
      name: 'Exchanges',
      url: '/exchanges',
      icon: 'icon-bubbles',
    },
    {
      name: 'Verification',
      url: '/verification',
      icon: 'icon-bulb',
    },
    {
      name: 'Update Operations',
      url: '/updateOperations',
      icon: 'icon-refresh',
    },
    {
      name: 'Export',
      url: '/export',
      icon: 'icon-folder',
    },
    // {
    //   name: 'Help',
    //   url: '/help',
    //   icon: 'icon-info',
    // }
  ]
};
