import React from 'react';

export default ({ title, subtitle, currentStep, totalStep }) => (
    <React.Fragment>
        <div style={styles.titleContainer}>
            <h4 style={{ padding: '0 1em 0 0' }}>{title}</h4>
            <h6>{subtitle}</h6>
        </div>
        <div style={styles.progressContainer}>
            <div style={styles.line}/>
            {createSteps(currentStep, totalStep)}
        </div>
    </React.Fragment>
);

const createSteps = (currentStep, totalStep) => {
    const stepViews = [];
    for (let step = 1; step <= totalStep; step++) {
        const stepView = createStep(step, currentStep);
        stepViews.push(stepView);
    }
    return stepViews;
};

const createStep = (step, currentStep) => {
    const background = currentStep === step ? '#63c2de' : '#e4e7ea';
    const isComplete = currentStep > step;
    return (
        <span style={{...styles.step, background: isComplete? '#e4e7ea' : background }}  key={step}>
            { isComplete
              ? <i className="cui-check" />
              : <span style={{ color: 'white' }}>{step}</span>
            }
        </span>
    );
}

const styles = {
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '64px 0 32px 0',
    },
    progressContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 0 64px 0',
    },
    line: {
        width: '100%',
        height: 1,
        background: '#acb4bc',
        position: 'absolute',
        top: 16,
        right: 0,
        left: 0,
        buttom: 0,
        margin: 'auto',
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
        width: 32,
        borderRadius: '50%',
        zIndex: 2,
    },
}
