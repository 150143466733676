import * as types from '../types';

const initialState = {
  transaction: [],
  loadingSummary: null,
  summary: {},
  yearEnd: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_TRANSACTION:
      return {
        ...state,
        status: types.DASHBOARD_TRANSACTION,
        transaction: action.payload,
      };
    case types.FETCH_COMPANY_SUMMARY:
      return {
        ...state,
        loadingSummary: true,
      };
    case types.FETCH_COMPANY_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingSummary: false,
        summary: action.payload.summary,
        yearEnd: action.payload.yearEnd || '',
      };
    case types.FETCH_COMPANY_SUMMARY_FAILED:
      return {
        ...state,
        loadingSummary: false,
      };
    default:
      return state;
  }
};
