import * as types from '../types';

const initialState = {
  selectedYearEndDate: '',
  selectedReportingCurrency: 'CAD',
  selectedFunctionalCurrency: 'CAD',
  selectedCryptocurrencies: [],
  selectedWalletAddresses: {},
  selectedPowers: {},
  selectedPools: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SELECTED_COMPANY_YEAR_END_DATE:
      return {
        ...state,
        status: types.SELECTED_COMPANY_YEAR_END_DATE,
        selectedYearEndDate: action.payload,
      }
    case types.SELECTED_REPORTING_CURRENCY:
      return {
        ...state,
        status: types.SELECTED_REPORTING_CURRENCY,
        selectedReportingCurrency: action.payload,
      }
    case types.SELECTED_FUNCTIONAL_CURRENCY:
      return {
        ...state,
        status: types.SELECTED_FUNCTIONAL_CURRENCY,
        selectedFunctionalCurrency: action.payload,
      }
    case types.SELECTED_CRIPT_CURRENCIES:
      return {
        ...state,
        status: types.SELECTED_CRIPT_CURRENCIES,
        selectedCryptocurrencies: action.payload,
      }
    case types.SELECTED_WALLET_ADDRESSES:
      return {
        ...state,
        status: types.SELECTED_WALLET_ADDRESSES,
        selectedWalletAddresses: action.payload,
      }
    case types.SELECTED_POWERS:
      return {
        ...state,
        status: types.SELECTED_POWERS,
        selectedPowers: action.payload,
      }
    case types.SELECTED_POOLS:
      return {
        ...state,
        status: types.SELECTED_POOLS,
        selectedPools: action.payload,
      }
    default:
      return state
  }
};