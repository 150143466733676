import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Footer } from '../../components';
import { Select } from '../../../../components';
import { setPools } from '../../../../redux/actions/CurrenciesActions';
import { isValidPool } from '../../../../utils/validation';
import { setSelectedPools } from '../../../../redux/actions/OperationSetupActions';

const formatPools = ({ options, selected }) => {
  var pools = {}

  // format dict
  selected.forEach((selCripto) => {
    const { value } = selCripto;  // BTC, DASH etc...
    if (value) pools[`${value}`] = [];
  })

  // push
  options.forEach((cripto) => {
    const { id, cryptoCurrencyCode, name } = cripto;
    if (id && cryptoCurrencyCode && name) {
      selected.forEach((selCripto) => {
        const { value } = selCripto;
        if (value && value === cryptoCurrencyCode) {
          pools[`${value}`].push({ name, id, selectedId: '' })
        }
      })
    }
  })
  return pools;
}

const formatErrors = ({ options, selected }) => {
  var errors = {}

  // format dict
  selected.forEach((selCripto) => {
    const { value } = selCripto;  // BTC, DASH etc...
    if (value) errors[`${value}`] = {};
  })

  // push
  options.forEach((cripto) => {
    const { id, cryptoCurrencyCode, name } = cripto;
    if (id && cryptoCurrencyCode && name) {
      selected.forEach((selCripto) => {
        const { value } = selCripto;
        if (value && value === cryptoCurrencyCode) {
          errors[`${value}`][`${name}`] = '';
        }
      })
    }
  })
  return errors;
}

class Pools extends Component {
  state = {
    pools: formatPools(this.props),
    errors: formatErrors(this.props),
  }

  componentDidMount() {
    const { options } = this.props;
    if (!options || options.length === 0) setPools();
  }

  onChange = (e) => {
    /*
       id = code (BTC, DASH)
       name = pool name (Slush Pool , nano pools)
       value = selected poolId
    */
    const { id, name, value } = e.target;
    var { pools } = this.state;
    var codePoolArray = pools[`${id}`];

    // set selected poolId
    codePoolArray.forEach((pool, idx) => {
      if (pool && pool.name && pool.id) {
        if (name === pool.name) {
          codePoolArray[idx].selectedId = value;
        }
      }
    })
    pools[`${id}`] = codePoolArray;
    this.setState({ pools });
  }

  validate = () => {
    const { pools } = this.state;

    let errors = formatErrors(this.props);
    let isError = false;

    // set errors
    Object.keys(pools).forEach((code) => {
      const codePoolArray = pools[`${code}`];
      var selectedPoolIdPerCode = false;

      // validation
      codePoolArray.forEach((pool)=> {
        const { name, selectedId } = pool;
        if (name && selectedId && isValidPool(selectedId)) {
          selectedPoolIdPerCode = true;
          errors[`${code}`][`${name}`] = '';
        } else {
          isError = true;
          errors[`${code}`][`${name}`] = `Please select ${code} pool`;
        }
      })

      // if one poolId selected per code at leat, turn error flag to false
      if (selectedPoolIdPerCode) {
        codePoolArray.forEach((pool)=> {
          const { name } = pool;
          if (name) {
            isError = false;
            errors[`${code}`][`${name}`] = '';
          }
        })
      }
    })

    this.setState({ errors });

    // format selected pool data
    if (!isError) {
      var selectedPools = {};

      // remove unselected pool ids
      Object.keys(pools).forEach((code) => {
        selectedPools[`${code}`] = [];
        const codePoolArray = pools[`${code}`];
        codePoolArray.forEach((pool)=> {
          const { name, selectedId } = pool;
          if (name && selectedId && isValidPool(selectedId)) {
            selectedPools[`${code}`].push({ name, id: selectedId });
          }
        })
      })

      // store to redux state
      console.log(selectedPools)
      setSelectedPools(selectedPools);
    }

    return isError
  }

  renderSelects = () => {
    const { pools, errors } = this.state;
    var selects = [];
    Object.keys(pools).forEach(code => {
      const codePoolArr = pools[`${code}`];
      if (codePoolArr && codePoolArr.length > 0) {
        codePoolArr.forEach((codePool) => {
          const { name , id, selectedId } = codePool;
          selects.push(
            <Select
              title={`${code}`}
              id={`${code}`}
              name={`${name}`}
              value={selectedId}
              options={[
                { value: '', title: 'Select a pool' },
                { value: id, title: name },
              ]}
              onChange={this.onChange}
              error={errors[`${code}`][`${name}`]}
              key={name}
            />
          )
        })
      }
    })
    return selects;
  }

  render() {
    const { nextOnClick } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Pools'}
            currentStep={3}
            totalStep={5}
          />
          <form
            className="needs-validation"
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            { this.renderSelects() }
          </form>
        </div>
        <div style={styles.footerContainer} >
          <Footer
            onClickPrev={() => nextOnClick("Currencies")}
            onClickNext={() => {

              // valification
              const error = this.validate();
              if (!error) nextOnClick("Wallet");
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
}

const mapStateToProps = state => {
  return {
    options: state.currencies.pools,
    selected: state.setup.selectedCryptocurrencies,
  }
}

export default connect(mapStateToProps)(Pools);
