import React from 'react';
import { AppBreadcrumb } from '@coreui/react';
import routes from '../../Home.module';
import ButtonLink from '../../../../components/ButtonLink';
import { logout } from '../../../../redux/actions/AuthActions';

export default () => (
  <div style={styles.container}>
    <span style={styles.breadcrumb}>
      <AppBreadcrumb appRoutes={routes}/>
    </span>
    <ButtonLink
      to="/login"
      title="Logout"
      className="breadcrumb"
      style={styles.button}
      onClick={() => logout()}
    />
  </div>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcrumb: {
    flex: 1,
    borderLeft: 'solid',
    borderColor: '#e4e5e6',
    borderWidth: 1,
  },
  button: {
    border: 'none',
    borderBottom: 'solid',
    borderColor: '#c8ced3',
    borderWidth: 1,
    color: '#73818f',
    outline: 0,
  },
};
