import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import Input from '../../components/Input';
import { isValidEmail, isValidPassword } from '../../utils/validation';
import { attemptLogin, login } from '../../redux/actions/AuthActions';
import { setCurrencies, setPools } from '../../redux/actions/CurrenciesActions';
import { setCompanyOperations } from '../../redux/actions/OperationActions';

export default class Form extends Component {
  state = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    serverError: '',
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name] : value });
  }

  validate = () => {
    const { email, password } = this.state;

    let isError = false;
    let errors = {
      emailError: '',
      passwordError: '',
    };

    if (!isValidEmail(email)) {
      isError = true;
      errors.emailError = 'Invalid email address';
    }

    if (!isValidPassword(password)) {
      isError = true;
      errors.passwordError = 'Must contain at least one number and one UPPERCASE and lowercase letter, and at least 8 or more characters';
    }

    this.setState({
      ...this.state,
      ...errors
    });

    return isError
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const error = this.validate(); // validation
    if (!error) {
      // Login action
      const { history } = this.props;
      const { email, password } = this.state;
      try {
        await attemptLogin(email, password);  // login
        await setCurrencies() // set fiat & criptocurrency
        await setPools()  // set pools
        const status = await setCompanyOperations()
        console.log('[info] onboarding status is ', status)
        status === 0
          ? history.push('/onboarding')
          : login();
      } catch(e) {
        this.setState({ serverError: 'Wrong email or password' });
      }
    };
  }

  render() {
    const { emailError, passwordError, serverError } = this.state;
    return (
      <Card>
        <CardBody>
          <h5 style={styles.header}>Client Login</h5>
          <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
            <Input
              title="Email"
              type="email"
              name="email"
              placeholder="your@email.com"
              onChange={this.onChange}
              error={emailError}
            />
            <Input
              title="Password"
              type="password"
              name="password"
              placeholder="your password"
              onChange={this.onChange}
              error={passwordError}
            />
            <Row>
              <div style={styles.footer}>
                <p style={styles.error}>{serverError}</p>
                <input type="submit" className="btn btn-primary" style={styles.submit} value="Login"/>
              </div>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  header: {
    margin: '0 0 32px 0',
  },
  submit: {
    margin: '16px 0 0 0',
  },
  footer: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    fontSize: 11,
  },
}
