import v8n from 'v8n';

export const isValidEmail = (email) => {
  v8n.extend({
    email: () => {
      const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i; // eslint-disable-line
      return value => regex.test(value);
    }
  });

  return v8n().string().email().test(email);
};

export const isValidPassword = (psw) => {
  v8n.extend({
    password: () => {
      const regex = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i; // eslint-disable-line
      return value => regex.test(value);
    }
  });

  return v8n().string().password().test(psw);
}

export const isValidMMDD = (dt) => {
  v8n.extend({
    mmdd: () => value => {
      const nums = value.split("/");
      if (nums.length !== 2) return false;
      const mm = Number(nums[0])
      const dd = Number(nums[1])
      if (!v8n().number(mm).test(NaN) || !v8n().number(mm).test(NaN)) return false;
      if (mm < 1 || mm > 12 || dd < 1 || dd > 31) return false;
      return true;
    }
  });
  return v8n().string().mmdd().test(dt);
}

export const isValidPool = (pool) => {
  if (pool.length === 0) return false;
  return true;
}

export const isValidPoolApiKey = (key) => {
  if (!key) return false;
  return key.length === 64;
}

export const isValidBTCPool = (pool) => {
  // TODO: add btc validation
  if (pool.length === 0) return false;
  return true;
}

export const isValidDASHPool = (pool) => {
  // TODO: add dash validation
  if (pool.length === 0) return false;
  return true;
}

const isValidBTCAddress = (address) => {
  v8n.extend({
    address: () => {
      // const regex1 = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/; // eslint-disable-line
      const regex1 = /^(?:[13][a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/
      // console.log('the rege1 value is ', regex1)
      // const regex2 = /^3[a-km-zA-HJ-NP-Z1-9]{33}$/; // eslint-disable-line
      return value => (regex1.test(value));
    },
  });

  return v8n().string().address().test(address);
}

const isValidDASHAddress = (address) => {
  v8n.extend({
    address: () => {
      const regex1 = /^7[a-km-zA-HJ-NP-Z1-9]{33}$/; // eslint-disable-line
      const regex2 = /^X[a-km-zA-HJ-NP-Z1-9]{33}$/; // eslint-disable-line
      return value => (regex1.test(value) || regex2.test(value));
    },
  });

  return v8n().string().address().test(address);
}

export const isValidAddress = (cripto, address) => {
  if (cripto === 'BTC') return isValidBTCAddress(address);
  if (cripto === 'DASH') return isValidDASHAddress(address);
  return address.length !== 0;
}

export const isValidElecCost = (cost) => {
  if (cost.length === 0) return false;
  return v8n().number(false).not.negative().test(Number(cost));
}

export const isValidElecPower = (power) => {
  if (power.length === 0) return false;
  return v8n().number(false).not.negative().test(Number(power));
}
