import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Footer } from '../../components';
import { MultiSelect } from '../../../../components';
import { setSelectedCurrencies } from '../../../../redux/actions/OperationSetupActions';
import { setCurrencies } from '../../../../redux/actions/CurrenciesActions';

class Currencies extends Component {
  state = {
    currencies: [],
    currencyError: '',
  }

  componentDidMount() {
    const { options } = this.props;
    if (!options || options.length === 0) setCurrencies();
  }

  onChange = selOptions => {
    let currencies = [];
    for (var key in selOptions) {
      if (selOptions.hasOwnProperty(key)) {     
        currencies.push(selOptions[key]);
        this.setState({currencyError: ''});
      }
    }
    this.setState({ currencies });
    setSelectedCurrencies(currencies);
  }

  validate = () => {
    const { selected } = this.props;

    let isError = false;
    let currencyError = '';

    if (selected.length === 0) {
      isError = true;
      currencyError = 'Please select at least one cryptocurrency.';
    }

    this.setState({ currencyError });

    return isError;
  }

  formatOptions = (options) => {
    let opt = [];
    options.forEach( c => {
      const { code, name } = c;
      if (code && name) {
        opt.push({ value: code, label: name });
      }
    });
    return opt;
  }

  render() {
    const { selected, options, nextOnClick } = this.props;
    const { currencyError } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <ProgressBar
            title={'Account Setup'}
            subtitle={'Cryptocurrencies'}
            currentStep={2}
            totalStep={5}
          />
          <MultiSelect
            title="Managed Cryptocurrencies"
            value={selected}
            options={this.formatOptions(options)}
            onChange={this.onChange}
            className="basic-multi-select"
            placeholder="Select from list of available cryptocurrencies"
            error={currencyError}
          />
        </div>
        <div style={styles.footerContainer} >
          <Footer
            onClickPrev={() => nextOnClick("Company")}  // back to company setup
            onClickNext={() => {
              const error = this.validate() // validation
              if (!error) nextOnClick("Pools");  // move to pools setup
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    flex: 4,
  },
  footerContainer: {
    flex: 1,
    width: '100%',
  },
  button: {
    border: 'none',
    outline: 0,
    color: '#20a8d8',
    background: 'white',
  }
}

const mapStateToProps = state => {
  return {
    options: state.currencies.cryptoCurrencies,
    selected: state.setup.selectedCryptocurrencies,
  }
}

export default connect(mapStateToProps)(Currencies);
