import * as types from '../types';

const INITIAL_STATE = {
  transactions: {},
  count: 0,

  poolTransactions: {},
  poolCount: 0,

  orderTransactions: {},
  orderCount: 0,
  transferTransactions: {},
  transferCount: 0,

  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_TRANSACTIONS:
      return {
        ...state,
        status: types.FETCH_TRANSACTIONS,
        loading: true,
        error: null,
      };

    case types.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        status: types.FETCH_TRANSACTIONS_SUCCESS,
        loading: false,
        transactions: action.payload.transactions,
        fiscalTransactions: action.payload.fiscalTransactions,
        count: action.payload.total,
        fiscalCount: action.payload.fiscalTotal,
        error: null,
      };

    case types.FETCH_TRANSACTIONS_FAILED:
      return {
        ...state,
        status: types.FETCH_TRANSACTIONS_FAILED,
        loading: false,
        error: action.payload,
      };

    case types.FETCH_POOL_TRANSACTIONS:
      return {
        ...state,
        status: types.FETCH_POOL_TRANSACTIONS,
        loading: true,
        error: null,
      };

    case types.FETCH_POOL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        status: types.FETCH_POOL_TRANSACTIONS_SUCCESS,
        loading: false,
        poolTransactions: action.payload.transactions,
        poolCount: action.payload.total,
        error: null,
      };

    case types.FETCH_POOL_TRANSACTIONS_FAILED:
      return {
        ...state,
        status: types.FETCH_POOL_TRANSACTIONS_FAILED,
        loading: false,
        error: action.payload,
      };

    case types.FETCH_EXCHANGES_TRANSACTIONS:
      return {
        ...state,
        status: types.FETCH_EXCHANGES_TRANSACTIONS,
        loading: true,
        error: null,
      };

    case types.FETCH_EXCHANGES_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        status: types.FETCH_EXCHANGES_TRANSACTIONS_SUCCESS,
        loading: false,
        orderTransactions: action.payload.orders,
        orderCount: action.payload.orderCount,
        transferTransactions: action.payload.transfers,
        transferCount: action.payload.transferCount,
        error: null,
      };

    case types.FETCH_EXCHANGES_TRANSACTIONS_FAILED:
      return {
        ...state,
        status: types.FETCH_EXCHANGES_TRANSACTIONS_FAILED,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
