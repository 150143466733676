import { combineReducers } from 'redux';
import Auth from './Auth';
import Transactions from './Transactions';
import OperationSetup from './OperationSetup';
import Currencies from './Currencies';
import Operation from './Operation';
import Dashboard from './Dashboard';
import Wallets from './Wallets';
import Exchanges from './Exchanges';
import Export from './Export';

export default combineReducers({
  auth: Auth,
  transactions: Transactions,
  setup: OperationSetup,
  currencies: Currencies,
  operation: Operation,
  dashboard: Dashboard,
  wallets: Wallets,
  exchanges: Exchanges,
  export: Export,
});
