import React from 'react';
import v8n from 'v8n';

const InputSelect = ({
  title = '',
  type,
  nameInput,
  nameSelect,
  valueInput,
  valueSelect,
  onChange,
  placeholder,
  options,
  error = '',
  maxLength = '50',
}) => {
  const isValidOptions = isValid(options);
  const className = error.length > 0
    ? 'form-control is-invalid'
    : 'form-control';
  return (
    <div className="form-group">
      <label>{title}</label>
      <div className="input-group">
        <input
          value={valueInput}
          type={type}
          name={nameInput}
          className={className}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        { isValidOptions &&
          <div className="input-group-prepend">
            <select
              value={valueSelect}
              name={nameSelect}
              onChange={onChange}
              className="input-group-text">
              {
                options.map((o, i) => (
                  <option value={o.value} key={i}>{o.title}</option>
                ))
              }
            </select>
          </div>
        }
        <div className="invalid-feedback">
          {error}
        </div>
      </div>
    </div>
  )
}

const isValid = (options) => {
  if (!v8n().array().test(options) || options.length === 0) return false;
  options.forEach(o => {
    if (!o.hasOwnProperty('value') || !o.hasOwnProperty('title')) return false;
  });
  return true;
}

export default InputSelect;
