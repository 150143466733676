import React, { Component } from 'react';
import Radium from 'radium';
import Header from './components/Header';
import { Company, Currencies, Pools, Wallet, Power, Complete } from './views';

class Onboarding extends Component {
  state = {
    name: 'Company',
  }

  getSubview = () => {
    switch (this.state.name) {
      case 'Company':
        return Company;
      case 'Currencies':
        return Currencies;
      case 'Pools':
        return Pools;
      case 'Wallet':
        return Wallet;
      case 'Power':
        return Power;
      case 'Complete':
        return Complete;
      default:
        return Company;
    }
  };

  render() {
    const Subview = this.getSubview();
    return (
      <div className="app">
        <Header />
        <div className="app-body" style={styles.container}>
          <div className="main animated fadeIn" style={styles.main}>
            <Subview nextOnClick={(next) => this.setState({ name: next })}/>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: 'white',
  },
  main: {
    maxWidth: 500,
    padding: '0 16px',
  },
}

export default Radium(Onboarding);
