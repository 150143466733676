import React, { Component } from 'react';
import { render } from 'react-dom';
import { StyleRoot } from 'radium';
import { Provider } from 'react-redux';
import store from './redux/store';
import Router from './router';
import './utils/polyfill';

// disable ServiceWorker
// import registerServiceWorker from './utils/registerServiceWorker';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <StyleRoot>
          <Router />
        </StyleRoot>
      </Provider>
    )
  }
}

render(<App/>, document.getElementById('root'));

// disable ServiceWorker
// registerServiceWorker();
