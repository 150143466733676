import React from 'react';
import Select from 'react-select';

export const Input = ({
  title = '',
  placeholder,
  onChange,
  value,
  options = [],
  error = '',
}) => {
  return (
    <div className="form-group">
      <label>{title}</label>
      <Select
        isMulti
        defaultValue={value}
        options={options}
        onChange={onChange}
        className="basic-multi-select"
        placeholder={placeholder}
      />
      <div style={styles.error}>
        {error}
      </div>
    </div>
  );
}

const styles = {
  error: {
    color: 'red',
    fontWeight: 'lighter',
    margin: '4px 0 0 0',
    fontSize: 12,
  },
}

export default Input;
