import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkLoginState } from './redux/actions/AuthActions';

// Styles
import '@coreui/icons/css/coreui-icons.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './styles/style.css';
import './styles/custom.css';

// Scenes
import {
  Login, Onboarding, Home, Page404, Page500,
} from './scenes';

import spinner from './assets/img/spinner.svg';

// import { renderRoutes } from 'react-router-config';

class Router extends Component {
  state = {
    didLoad: false,
  }

  componentWillMount() {
    const { history } = this.props;

    // check login state
    checkLoginState(history).then(() => {
      this.setState({ didLoad: true });
    }).catch(() => {
      this.setState({ didLoad: true });
    })
  }

  render() {
    const { didLoad } = this.state;
    const { loggedIn } = this.props;

    if (!didLoad) return (
      <img
        src={spinner}
        className="spinner"
        alt="spinner"
      />
    )

    return (
      <BrowserRouter>
        <div>
          { loggedIn
            ? (
              <Switch>
                <Route exact path="/404" component={Page404} />
                <Route exact path="/500" component={Page500} />
                <Route exact path="/" component={Home} />
                <Route component={Home} />
                <Redirect to="/" />
              </Switch>
            )
            : (
              <Switch>
                <Route exact path="/onboarding" component={Onboarding} />
                <Route exact path="/404" component={Page404} />
                <Route exact path="/500" component={Page500} />
                <Route exact path="/" component={Login} />
                <Route component={Login} />
                <Redirect to="/" />
              </Switch>
            )}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
  };
}

export default connect(mapStateToProps)(Router);
