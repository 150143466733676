import React, { Component } from 'react';
import Radium from 'radium';

import logo from '../../assets/img/brand/logo.svg';

class Header extends Component {
  render() {
    const { height } = this.props;
    return (
      <div className="textarea" style={[styles.container, { height }]}>
        <div style={styles.flexContainer}>
          <img src={logo} style={styles.logo} alt={'BlockVantage logo'}/>
          <div style={styles.buttonContainer}>
            {/*<button style={styles.button}>About</button>
            <button style={styles.button}>Contact</button>*/}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: 187,
    padding: '0 15%',
    backgroundColor: 'white',
    '@media (max-width: 625px)': {
      padding: '0 16',
    },
  },
  flexContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 625px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonContainer: {
    minWidth: 200,
    '@media (max-width: 625px)': {
      width: 0,
      minWidth: 0,
      visibility: 'hidden',
    },
  },
  button: {
    fontSize: '1.2em',
    fontWeight: '600',
    borderWidth: 0,
    margin: '0 16px',
    backgroundColor: 'white',
    outline: 0,
  },
  logo: {
    width: 145,
    height: 36,
  },
}

export default Radium(Header);
