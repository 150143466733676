import React from 'react';

const renderSecondInput = (
  value,
  placeholder,
  onChange,
  className,
) => (
  <div style={{ margin: '4px 0 0 0' }}>
    <input
      value={value}
      type="text"
      className={className}
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
);

const renderNameInput = (
  value,
  placeholder,
  onChange,
  className,
) => (
  <div style={{ margin: '4px 0 0 0' }}>
    <input
      value={value}
      type="text"
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={100}
    />
  </div>
);

export const Input = ({
  title,
  type,
  id,
  name,
  value,
  placeholder,
  onChange,
  error = '',
  maxLength = '100',
  showDelete = false,
  onClickDelete,
  // below props are for the second input
  showSecondInput = false,
  value2,
  placeholder2,
  onChange2,
  // props for optional name input
  showNickNameInput = false,
  nameValue,
  nameOnChange,
  namePlaceHolder,
}) => {
  const className = error.length > 0
    ? 'form-control is-invalid'
    : 'form-control';
  return (
    <div className="form-group">
      { title && <label>{title}</label> }
      <input
        value={value}
        type={type}
        id={id}
        name={name}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      { showSecondInput && renderSecondInput(value2, placeholder2, onChange2, className) }
      { showNickNameInput && renderNameInput(nameValue, namePlaceHolder, nameOnChange, className) }
      <div className="invalid-feedback">
        {error}
      </div>
      { showDelete && onClickDelete &&
        <button
          style={styles.delete}
          onClick={onClickDelete}>
        x Delete</button>
      }
    </div>
  );
}



const styles = {
  delete: {
    padding: 0,
    margin: 0,
    color: '#c8ced3',
    fontSize: 10,
    border: 'none',
    outline: 0,
    backgroundColor: 'white',
  },
}

export default Input;
